import React from "react"

const MinorHeading = ({ text, classes, style }) => {
  return (
    <h3
      className={`text-2xl font-extrabold leading-8 ${classes}`}
      style={style}
    >
      {text}
    </h3>
  )
}

export default MinorHeading
