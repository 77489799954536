import React from "react"

import Container from "../layout/Container"
import MajorHeading from "./MajorHeading"
import EMailIcon from "../../../content/images/envelope-solid-primary.png"

import { useSiteMetadata } from "../../static-queries/useSiteMetadata"

const ContactSection = () => {
  const { contactEmail } = useSiteMetadata()
  const sectionTitle = "What's Your Story?"
  const sectionLead = "Get in Touch."

  return (
    <Container>
      <div className="text-center text-scheme1-primary text-opacity-100">
        <MajorHeading text={sectionTitle} classes="text-opacity-100" />
        <MajorHeading text={sectionLead} classes="text-opacity-100" />
        <div className="mt-12 grid justify-center content-center">
          <a href={`mailto:${contactEmail}`}>
            {" "}
            <img
              src={EMailIcon}
              alt="Send us an email"
              width="50"
              height="50"
            />
          </a>
        </div>
      </div>
    </Container>
  )
}

export default ContactSection
