import React from "react"

const OutlineButton = ({ text, link, targetBlank }) => {
  return (
    <a
      className="inline-block px-10 py-4 font-semibold transition duration-300 bg-transparent border-2 rounded-3xl hover:bg-neutral-light border-neutral-light text-neutral-light hover:text-neutral-black"
      href={link}
      target={targetBlank ? "_blank" : ""}
      rel={targetBlank ? "noopener noreferrer" : ""}
      role="button"
    >
      {text}
    </a>
  )
}

export default OutlineButton
