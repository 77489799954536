import React from "react"

const PageSubtitle = ({ text, classes }) => {
  return (
    <h2 className={`text-4xl font-extrabold tracking-tighter ${classes}`}>
      {text}
    </h2>
  )
}

export default PageSubtitle
