import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import Container from "../layout/Container"
import MajorHeading from "./MajorHeading"
import Paragraph from "./Paragraph"

const ImageSection = ({
  title,
  mainText,
  imageFluid,
  imageAlt,
  alternateOrientation,
  children,
}) => {
  return (
    <Container>
      <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-20 gap-y-16 lg:gap-y-0">
        <div
          className={`${alternateOrientation ? "lg:order-2" : "lg:order-1"}`}
        >
          <div>
            <MajorHeading text={title} />
            <Paragraph text={mainText} classes="mt-6" />
          </div>
          {children ? <div className="mt-6">{children}</div> : null}
        </div>
        <div
          className={`${alternateOrientation ? "lg:order-1" : "lg:order-2"}`}
        >
          <GatsbyImage image={imageFluid} alt={imageAlt} className={"rounded-3xl"} />
        </div>
      </div>
    </Container>
  )
}

export default ImageSection
