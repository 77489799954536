import React from "react"

import MajorHeading from "./MajorHeading"
import Paragraph from "./Paragraph"

const TextSection = ({ title, lead, alignment, children }) => {
  let textAlignment = "text-left"

  switch (alignment) {
    case "left":
      textAlignment = "text-left"
      break
    case "center":
      textAlignment = "text-center"
      break
    case "right":
      textAlignment = "text-right"
      break
    default:
      textAlignment = "text-left"
      break
  }

  return (
    <div>
      <MajorHeading text={title} classes={textAlignment} />
      <Paragraph text={lead} classes={`mt-6 ${textAlignment}`} />
      {children ? <div className="mt-6">{children}</div> : null}
    </div>
  )
}

export default TextSection
