import React from "react"

const Paragraph = ({ text, classes, style }) => {
  return (
    <p className={`leading-8 ${classes}`} style={style}>
      {text}
    </p>
  )
}

export default Paragraph
