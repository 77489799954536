import React from "react"

const MajorHeading = ({ text, classes }) => {
  return (
    <h2
      className={`text-5xl lg:text-6xl font-extrabold tracking-tighter ${classes}`}
    >
      {text}
    </h2>
  )
}

export default MajorHeading
