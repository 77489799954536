import React from "react"
import { useInView } from "react-intersection-observer"
import { GatsbyImage } from "gatsby-plugin-image"

import Container from "../layout/Container"
import RevealSection from "../ui-elements/RevealSection"
import Feature from "../ui-elements/Feature"
import ImageSection from "../ui-elements/ImageSection"
import TextSection from "../ui-elements/TextSection"
import ContactSection from "../ui-elements/ContactSection"
import PageTitle from "../ui-elements/PageTitle"
import PageSubtitle from "../ui-elements/PageSubtitle"
import Claim from "../ui-elements/Claim"
import OutlineButton from "../ui-elements/OutlineButton"

const IndexPageTemplate = ({ pageContent }) => {
  const {
    contentLanguage,
    hero,
    features,
    appVr,
    biofeedback,
    breathing,
    restoration,
    vr,
    science1,
    science2,
    storeButtonMeta,
  } = pageContent

  const [refFeatureSection, inViewFeatureSection] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const [refAppVrSection, inViewAppVrSection] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const [refBiofeedbackSection, inViewBiofeedbackSection] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const [refBreathingSection, inViewBreathingSection] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const [refRestorationSection, inViewRestorationSection] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const [refVrSection, inViewVrSection] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const [refScience1Section, inViewScience1Section] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const [refScience2Section, inViewScience2Section] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  }) 
  const [refContactSection, inViewContactSection] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  return (
    <div>
      <div
        id="hero"
        className="container flex flex-col px-8 mx-auto overflow-hidden lg:pt-16 xlg:pb-12 lg:min-h-screen"
      >
        <div className="flex-grow grid pb-12 lg:pb-32 items-center lg:grid-cols-2 gap-x-40">
          <div id="text">
            <PageTitle text={hero.title} classes="mt-12 text-scheme1-primary" />
            <PageSubtitle
              text={hero.subtitle}
              classes="mt-8 text-scheme1-secondary"
            />
            <Claim text={hero.claim} classes="mt-8" />
            <div className="mt-8">
              <OutlineButton
                text={storeButtonMeta.text}
                link={storeButtonMeta.url}
                targetBlank
              />
            </div>
          </div>
          <div id="trailer">
            <div className="mt-12 lg:mt-0 rounded-3xl lg:overflow-hidden">
              <video muted autoPlay playsInline loop>
                <source src={hero.trailer.url.publicURL} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <RevealSection
        reference={refFeatureSection}
        inView={inViewFeatureSection}
        classes="bg-primary-light"
      >
        <Container>
          <div className="block lg:space-x-4 lg:flex">
            {features.map(feature => (
              <Feature
                key={feature.title}
                title={feature.title}
                mainText={feature.mainText}
              />
            ))}
          </div>
        </Container>
      </RevealSection>

      <RevealSection
        reference={refAppVrSection}
        inView={inViewAppVrSection}
        largePadding
      >
        <ImageSection
          title={appVr.title}
          mainText={appVr.mainText}
          imageFluid={appVr.image.url.childImageSharp.gatsbyImageData}
          imageAlt={appVr.image.alt}
          alternateOrientation
        >
          <OutlineButton
            text={appVr.review.text}
            link={appVr.review.url}
            targetBlank
          />
        </ImageSection>
      </RevealSection>

      <RevealSection
        reference={refBiofeedbackSection}
        inView={inViewBiofeedbackSection}
        largePadding
      >
        <ImageSection
          title={biofeedback.title}
          mainText={biofeedback.mainText}
          imageFluid={biofeedback.image.url.childImageSharp.gatsbyImageData}
          imageAlt={biofeedback.image.alt}
        />
      </RevealSection>

      <RevealSection
        reference={refBreathingSection}
        inView={inViewBreathingSection}
        largePadding
      >
        <ImageSection
          title={breathing.title}
          mainText={breathing.mainText}
          imageFluid={breathing.image.url.childImageSharp.gatsbyImageData}
          imageAlt={breathing.image.alt}
          alternateOrientation
        />
      </RevealSection>

      <RevealSection
        reference={refRestorationSection}
        inView={inViewRestorationSection}
        largePadding
      >
        <ImageSection
          title={restoration.title}
          mainText={restoration.mainText}
          imageFluid={restoration.image.url.childImageSharp.gatsbyImageData}
          imageAlt={restoration.image.alt}
        />
      </RevealSection>

      <RevealSection
        reference={refVrSection}
        inView={inViewVrSection}
        largePadding
      >
        <ImageSection
          title={vr.title}
          mainText={vr.mainText}
          imageFluid={vr.image.url.childImageSharp.gatsbyImageData}
          imageAlt={vr.image.alt}
          alternateOrientation
        />
      </RevealSection>

      <RevealSection
        reference={refScience1Section}
        inView={inViewScience1Section}
        largePadding
      >
        <ImageSection
          title={science1.title}
          mainText={science1.mainText}
          imageFluid={science1.image.url.childImageSharp.gatsbyImageData}
          imageAlt={science1.image.alt}
        >
          <div className="flex flex-col lg:flex-row items-start space-y-4 space-x-0 lg:space-y-0 lg:space-x-4">
            <OutlineButton
              text={science1.blum.urlText}
              link={science1.blum.url}
              targetBlank
            />
            <OutlineButton
              text={science1.rockstroh.urlText}
              link={science1.rockstroh.url}
              targetBlank              
            />
            <OutlineButton
              text={science1.is.urlText}
              link={science1.is.url}
              targetBlank
            />
          </div>
        </ImageSection>
      </RevealSection>

      <RevealSection
        reference={refScience2Section}
        inView={inViewScience2Section}
        largePadding
      >
        <ImageSection
          title={science2.title}
          mainText={science2.mainText}
          imageFluid={science2.image.url.childImageSharp.gatsbyImageData}
          imageAlt={science2.image.alt}
          alternateOrientation
        >
          <OutlineButton
            text={science2.citation.urlText}
            link={science2.citation.url}
            targetBlank
          />
        </ImageSection>
      </RevealSection>    

      <RevealSection
        reference={refContactSection}
        inView={inViewContactSection}
        classes="bg-primary-light"
      >
        <ContactSection lang={contentLanguage} />
      </RevealSection>
    </div>
  )
}

export default IndexPageTemplate
