import React from "react"

import MinorHeading from "./MinorHeading"
import Paragraph from "./Paragraph"

const Feature = ({ title, mainText }) => {
  return (
    <div className="w-full mt-10 lg:text-center lg:mt-0">
      <MinorHeading text={title} />
      <Paragraph text={mainText} classes="mt-5" />
    </div>
  )
}

export default Feature
