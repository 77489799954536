import React from "react"

const PageTitle = ({ text, classes }) => {
  return (
    <h1 className={`text-6xl md:text-6-xl font-extrabold tracking-tighter ${classes}`}>
      {text}
    </h1>
  )
}

export default PageTitle
