import React from "react"

import { motion } from "framer-motion"

const RevealSection = ({ reference, inView, largePadding, classes, children }) => (
  <motion.section
    animate={{ opacity: inView ? 1 : 0 }}
    transition={{ duration: 0.3 }}
    className={`py-16 ${largePadding ? "md:py-48" : "md:py-28"} ${classes}`}
    ref={reference}
  >
    {children}
  </motion.section>
)

export default RevealSection
